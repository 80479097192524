export const statisticMenus = [
  {
    code: '1',
    text: '按故障统计',
    children: [
      {
        code: '1-1',
        text: '按月统计',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 1,
      },
      {
        code: '1-2',
        text: '按行政区域统计',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 2,
      },
      {
        code: '1-3',
        text: '按使用单位统计',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 3,
      },
      {
        code: '1-4',
        text: '按维保机构统计',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 4,
      },
      {
        code: '1-5',
        text: '按使用场所统计',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 5,
      },
      {
        code: '1-6',
        text: '按故障原因统计',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 6,
      },
      {
        code: '1-7',
        text: '按电梯统计',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 7,
      },
      {
        code: '1-8',
        text: '按品牌统计',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 8,
      },
      {
        code: '1-9',
        text: '按照设备生产商',
        interfaceName: 'getMaintenanceStatistic',
        querytype: 9,
      },
    ],
  },
  {
    code: '2',
    text: '按维保执行情况统计',
    children: [
      {
        code: '2-1',
        text: '按电梯分布统计',
        children: [
          {
            code: '2-1-1',
            text: '按行政区域统计',
            interfaceName: 'getMaintenanceLiftStatistic',
            querytype: 1,
          },
          {
            code: '2-1-2',
            text: '按使用单位统计',
            interfaceName: 'getMaintenanceLiftStatistic',
            querytype: 2,
          },
          {
            code: '2-1-3',
            text: '按维保单位统计',
            interfaceName: 'getMaintenanceLiftStatistic',
            querytype: 3,
          },
        ]
      },
      {
        code: '2-2',
        text: '按保养情况统计',
        children: [
          {
            code: '2-2-1',
            text: '按行政区域统计',
            interfaceName: 'getMaintenanceMainStatistic',
            querytype: 1,
          },
          {
            code: '2-2-2',
            text: '按使用单位统计',
            interfaceName: 'getMaintenanceMainStatistic',
            querytype: 2,
          },
          {
            code: '2-2-3',
            text: '按维保单位统计',
            interfaceName: 'getMaintenanceMainStatistic',
            querytype: 3,
          },
          {
            code: '2-2-4',
            text: '按维保小组统计',
            interfaceName: 'getMaintenanceMainStatistic',
            querytype: 4,
          },
          {
            code: '2-2-5',
            text: '按维保人员统计',
            interfaceName: 'getMaintenanceMainStatistic',
            querytype: 5,
          },
        ]
      },
      {
        code: '2-3',
        text: '按维修情况统计',
        children: [
          {
            code: '2-3-1',
            text: '按行政区域统计',
            interfaceName: 'getMaintenanceKeepStatistic',
            querytype: 1,
          },
          {
            code: '2-3-2',
            text: '按使用单位统计',
            interfaceName: 'getMaintenanceKeepStatistic',
            querytype: 2,
          },
          {
            code: '2-3-3',
            text: '按维保单位统计',
            interfaceName: 'getMaintenanceKeepStatistic',
            querytype: 3,
          },
          {
            code: '2-3-4',
            text: '按维保小组统计',
            interfaceName: 'getMaintenanceKeepStatistic',
            querytype: 4,
          },
          {
            code: '2-3-5',
            text: '按维保人员统计',
            interfaceName: 'getMaintenanceKeepStatistic',
            querytype: 5,
          },
        ]
      },
    ]
  },
  {
    code: '3',
    text: '按人员分布统计',
    children: [
      {
        code: '3-1',
        text: '按维保单位人员分布统计',
        interfaceName: 'getUserStatistic',
        querytype: 1,
      },
      {
        code: '3-2',
        text: '按维保小组人员分布统计',
        interfaceName: 'getUserStatistic',
        querytype: 2,
      },
    ],
  },
]
export const statisticMenusDefault = {
  code: '1-1',
  text: '按月统计',
  interfaceName: 'getMaintenanceStatistic',
  querytype: 1,
};

export const malfunctionMenus = [
  {
    code: '1',
    text: '按月份统计',
    interfaceName: 'getIotStatistic',
    querytype: 1,
  },
  {
    code: '2',
    text: '按行政区域统计',
    interfaceName: 'getIotStatistic',
    querytype: 2,
  },
  {
    code: '3',
    text: '按使用单位统计',
    interfaceName: 'getIotStatistic',
    querytype: 3,
  },
  {
    code: '4',
    text: '按维保单位统计',
    interfaceName: 'getIotStatistic',
    querytype: 4,
  },
  {
    code: '5',
    text: '按使用场所统计',
    interfaceName: 'getIotStatistic',
    querytype: 5,
  },
  {
    code: '6',
    text: '按故障类型统计',
    interfaceName: 'getIotStatistic',
    querytype: 6,
  },
  {
    code: '7',
    text: '按照电梯统计',
    interfaceName: 'getIotStatistic',
    querytype: 7,
  },
  {
    code: '8',
    text: '按照品牌统计',
    interfaceName: 'getIotStatistic',
    querytype: 8,
  },
  {
    code: '9',
    text: '按设备生产商统计',
    interfaceName: 'getIotStatistic',
    querytype: 9,
  },
  {
    code: '10',
    text: '按时间段统计',
    interfaceName: 'getIotStatistic',
    querytype: 10,
  },
]
export const malfunctionMenuDefault = {
  code: '1',
  text: '按月份统计',
  interfaceName: 'getIotStatistic',
  querytype: 1,
}

export const titles = [
  '物联网故障统计','一键报警统计','AI事件统计','电梯事件统计','困人故障统计'
]