<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="top-page">
      <a-radio-group style="margin-top: 4px;margin-left:-53px;transform:scale(0.9);width: 1300px" :default-value="1" button-style="solid" @change="change">
      <a-radio-button :value="1">
        按月份统计
      </a-radio-button>
      <a-radio-button :value="2">
        按行政区域统计
      </a-radio-button>
      <a-radio-button :value="3">
        按使用单位统计
      </a-radio-button>
      <a-radio-button :value="4">
        按维保单位统计
      </a-radio-button>
      <a-radio-button :value="5">
        按使用场所统计
      </a-radio-button>
      <a-radio-button v-if="eventtype!='30'" :value="6">
        按类型统计
      </a-radio-button>
      <a-radio-button :value="7">
        按电梯统计
      </a-radio-button>
      <a-radio-button :value="8">
        按品牌统计
      </a-radio-button>
      <a-radio-button :value="9">
        按设备生产商统计
      </a-radio-button>
      <a-radio-button :value="10">
        按时间段统计
      </a-radio-button>
    </a-radio-group>
    </div>
    <div class="detail-page">
      <div class="page-content">
        <statistic-page v-if="eventtype=='3'" ref="statisticpage" :querytype="querytype" :eventtype="eventtype"></statistic-page>
        <statistic-page1 v-if="eventtype=='1'" ref="statisticpage1" :querytype="querytype" :eventtype="eventtype"></statistic-page1>
        <statistic-page2 v-if="eventtype=='2'" ref="statisticpage2" :querytype="querytype" :eventtype="eventtype"></statistic-page2>
        <statistic-page3 v-if="eventtype=='4'" ref="statisticpage3" :querytype="querytype" :eventtype="eventtype"></statistic-page3>
        <statistic-page4 v-if="eventtype=='30'" ref="statisticpage3" :querytype="querytype" :eventtype="eventtype"></statistic-page4>
      </div>
    </div>
  </page-container>
</template>
<script>
import { malfunctionMenus, malfunctionMenuDefault } from '@/json/bigdata.js'
import StatisticPage from 'V/dsjfzjc/aiNetwork/malfunction/StatisticPage'
import StatisticPage1 from 'V/dsjfzjc/aiNetwork/malfunction/StatisticPage1'
import StatisticPage2 from "V/dsjfzjc/aiNetwork/malfunction/StatisticPage2";
import StatisticPage3 from "V/dsjfzjc/aiNetwork/malfunction/StatisticPage3";
import StatisticPage4 from "V/dsjfzjc/aiNetwork/malfunction/StatisticPage4";
import {titles} from "@/json/bigdata";
export default {
  components: {
    StatisticPage,
    StatisticPage1,
    StatisticPage2,
    StatisticPage3,
    StatisticPage4,
  },
  props: {
    eventtype: {
      default: '1'
    },
  },
  data() {
    return {
      pageTitle: this.eventtype=='30'?titles[4]:titles[this.eventtype-1],
      malfunctionMenus,
      breadcrumb: [
        {
          name: '大数据辅助决策',
          path: ''
        },
        {
          name: 'AI+物联网统计分析',
          path: ''
        },
        {
          name: this.eventtype=='30'?titles[4]:titles[this.eventtype-1],
          path: ''
        },
      ],
      selectedKeys: [malfunctionMenuDefault.code],
      interfaceName: malfunctionMenuDefault.interfaceName,
      querytype: malfunctionMenuDefault.querytype,
    }
  },
  methods: {
    change(e){
      this.selectedKeys = [e.target.value];
      this.interfaceName = malfunctionMenuDefault.interfaceName;
      this.querytype = e.target.value;
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-page {
  display: flex;
  padding: 10px;
  .left-tabs {
    flex-shrink: 0;
    width: 180px;
    min-height: calc(100vh - 120px);
    padding-top: 20px;
    background-color: white;
    border-radius: 2px;
  }
  .page-content {
    flex-grow: 1;
    margin-right: 15px;
    margin-left: 10px;
  }
}
.top-page{
  margin-top: 10px;
  height: 40px;
  //flex-grow: 1;
  margin-left: 20px;
  margin-right: 10px;
  background-color: white;
}
</style>