<!--
 * @Author: fengjingwei
 * @Date: 2021-09-13 20:16:34
 * @Description: 故障分析
-->
<template>
  <div class="statistic-container">
    <div class="box-container sm-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="时间范围" prop="starttime">
              <a-range-picker v-model="timerange">
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="故障类型" prop="eventcode">
              <a-select v-model="eventcode">
                <a-select-option v-for="(item, index) in faultOptions" :key="index" :value="item.eventcode">{{item.eventname}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="querytype==2" label="区域等级" prop="arealevel">
              <a-select v-model="arealevel">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="2">省</a-select-option>
                <a-select-option :value="4">市</a-select-option>
                <a-select-option :value="6">县</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="电梯类型" prop="liftpropertyid">
              <a-select v-model="liftpropertyid">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in liftpropertyidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
          <div class="query-btns">
            <a-button @click="resetQueryParams">重置</a-button>
            <a-button type="primary" class="after-btn" @click="initTableData(true)">查询</a-button>
            <a-button class="after-btn" @click="exportData(true)">导出</a-button>
          </div>
        </div>
      </div>
    </div>
    <template>
      <div ref="echarts" class="echars-container" style="margin-top: 10px"></div>
      <div class="box-container sm-container" style="margin-top: 10px;">
        <div class="box-container-inner">
          <a-table :columns="tableColumns" :data-source="tableData" row-key="keyname" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
            <span slot="keyname_month" slot-scope="value">
              <span>{{value?moment(value, 'YYYYMM').format('YYYY-MM'):''}}</span>
            </span>
            <span slot="num1" slot-scope="value">
              <span>{{(value/60).toFixed(2)}}小时</span>
            </span>
            <span slot="num2" slot-scope="value">
              <span>{{value}}</span>
            </span>
            <span slot="num3" slot-scope="value">
              <span>{{value}}</span>
            </span>
            <span slot="num4" slot-scope="value">
              <span>{{value}}</span>
            </span>
            <span slot="num5" slot-scope="value">
              <span>{{value.toFixed(2)}}公里</span>
            </span>
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts';
import pagination from '@/mixins/pagination'
import terminaltypes from '@/json/terminaltypes'
import { getIotStatistic, exportFaultExcel } from 'A/bigdata.js'
import {getEventListByCondition} from "A/wlw";
import {getLiftListByCondition} from "A/jcgn";
export default {
  mixins: [pagination],
  props: {
    interfaceName: {
      default: ''
    },
    querytype: {
      default: ''
    },
    eventtype: {
      default: '1'
    },
  },
  data() {
    return {
      moment,
      ec: null,
      terminaltypes,
      showAdvanced: false,
      queryParams: {
        starttime: '',
        endtime: '',
      },
      eventcode:'',
      arealevel:'',
      timerange: null,
      faultOptions:[],
      liftpropertyid:'',
      liftpropertyidOptions:[],
      allDatas: [],
      tableKey: '',
      tableColumns: [],
      tableData: [],
      tableLoading: false,
      counts:'',
    }
  },
  watch: {
    querytype(val) {
      if(val) {
        this.initTableData();
      }
    },
    timerange(val) {
      if(val && val.length) {
        this.queryParams.starttime = moment(val[0]).format('YYYYMMDD');
        this.queryParams.endtime = moment(val[1]).format('YYYYMMDD');
      }else {
        this.queryParams.starttime = '';
        this.queryParams.endtime = '';
      }
    }
  },
  mounted() {
    this.init();
    this.initFaultOptions();
  },
  methods: {
    init() {
      this.ec = echarts.init(this.$refs.echarts);
      this.initTableData();
      this.initLiftpropertyidOptions();
    },
    initFaultOptions(){
      let params={
        eventtype:'1'
      }
      getEventListByCondition(params).then(res=>{
        if (res && res.returncode == '0') {
          this.faultOptions = res.item;
          this.faultOptions.unshift({eventcode:"",eventname:"请选择"})
        }
      })
    },
    initLiftpropertyidOptions() {
      let params = {
        liftpropertytype: '1',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.liftpropertyidOptions = res.item;
          this.liftpropertyidObj = this.liftpropertyidOptions.reduce((a,b)=>{
            a[b.liftpropertyid] = b.value
            return a
          },{})
          this.getTableData();
        }
      })
    },
    resetQueryParams() {
      this.timerange = null;
      this.$refs.queryForm.resetFields();
      this.eventcode='';
      // this.$refs.advancedForm.resetFields();
    },
    initTableData() {
      this.tableColumns = [
        {
          title: '分类',
          dataIndex: 'keyname',
          key: 'keyname',
          ellipsis: true,
        },
        {
          title: '故障数',
          dataIndex: 'num',
          key: 'num',
          ellipsis: true,
        },
      ];
      this.tableLoading = true;
      let params = {
        querytype: this.querytype,
        eventtype: this.eventtype,
        eventcode: this.eventcode,
        arealevel:this.arealevel,
        liftpropertyid:this.liftpropertyid,
        ...this.queryParams,
      };
      getIotStatistic(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.allDatas = res.item;
          this.pagination.total=res.count;
          this.getTableData();
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableData = this.allDatas.slice((this.currentPage-1)*this.pagination.pageSize, this.currentPage*this.pagination.pageSize)
      this.pkgEchartData();
    },
    pkgEchartData() {
      let xdata = [],series = [];
      let legendName = ['故障数'];
      let xkey = 'keyname', ykeys = ['num'], legend = legendName;
      this.tableData.forEach(item => {
        xdata.push(item[xkey]);
      });
      let i=0;
      ykeys.forEach(ykey => {
        let ydata = [];
        this.tableData.forEach(item => {
          ydata.push(item[ykey]);
        })
        series.push({
          name: legendName[i++],
          data: ydata,
          type: 'bar'
        });
      })
      let xLabelWidth = 0;
      try {
        let echartsWidth = parseInt(getComputedStyle(this.$refs.echarts).width);
        let xAxisWidth = echartsWidth * 0.8 - 50;
        xLabelWidth = xAxisWidth/xdata.length;
      }catch(e) {}
      let option = {
        legend: {
          data: legend,
          selected:{
          }
        },
        // grid: {
        //   bottom: 90
        // },
        xAxis: {
          type: 'category',
          axisLabel: xLabelWidth?{
            interval: 0,
            // rotate: 23,
            width: xLabelWidth,
            overflow: 'truncate',
          }:{},
          data: xdata
        },
        yAxis: {
          type: 'value'
        },
        series: series,
        tooltip: {
          show: true,
          trigger: 'axis'
        }
      };
      this.ec.setOption(option);
    },
    exportData() {
      let params = {
        querytype: this.querytype,
        eventtype: this.eventtype,
        eventcode: this.eventcode,
        arealevel:this.arealevel,
        liftpropertyid:this.liftpropertyid,
        ...this.queryParams,
      };
      this.showLoading();
      exportFaultExcel(params).then(()=>{
        this.hideLoading();
      }).catch(()=>{
        this.hideLoading();
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.statistic-container {
  height: calc(100vh - 120px);
  overflow-y: auto;
  // border: solid 1px red;
  .echars-container {
    height: 45%;
    background-color: white;
    margin-top: 10px;
    border-radius: 2px;
  }
}
</style>