import { render, staticRenderFns } from "./StatisticPage2.vue?vue&type=template&id=2531cd7a&scoped=true&"
import script from "./StatisticPage2.vue?vue&type=script&lang=js&"
export * from "./StatisticPage2.vue?vue&type=script&lang=js&"
import style0 from "./StatisticPage2.vue?vue&type=style&index=0&id=2531cd7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2531cd7a",
  null
  
)

export default component.exports